// All styles for About Text Component

#aboutStory {
  padding: 200px 0px 0px 0px;
  @media (max-width: 480px) {
    padding: 100px 0 0 0;
  }
  @media (min-width: 2000px) {
    padding: 400px 0 0 0px;
  }
  h2 {
    margin-bottom: 50px;
  }
  .story-left {
    @media (max-width: 1200px) {
      margin-bottom: 75px;
    }
  }
  .story-right {
    padding-top: 10px;
  }
  .story-bottom {
    margin-top: 50px;
  }
  a {
    color: #aaa;
  }
}
