// All styles for Header

header {
  padding: 4vw 0px 0px 0px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  background: transparent;
  @media (max-width: 768px) {
    padding: 7vw 0 0 0;
  }
  @media (max-width: 480px) {
    padding: 8vw 0 0 0;
  }
  .header-left {
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
    @media (max-width: 480px) {
      margin-bottom: 15px;
    }
    .logo {
      a {
        &:after {
          display: none;
        }
      }
      span {
        font-family: "Teko", sans-serif;
        font-weight: 300;
        letter-spacing: 1px;
        font-size: 1.6em;
        color: #fff;
        @media (max-width: 480px) {
          font-size: 1em;
        }
      }
    }
  }
  .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 6px;
    @media (max-width: 768px) {
      justify-content: flex-start;
    }
    .main-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: right;
    }
    li {
      display: inline-block;
      a {
        color: #aaa;
        font-weight: 400;
        margin-left: 60px;
        font-size: 1em;
        position: relative;
        transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        @media (max-width: 768px) {
          margin-left: 0px;
          margin-right: 30px;
        }
        @media (max-width: 480px) {
          font-size: 0.8em;
        }
        &:after {
          content: "";
          height: 1px;
          width: 0%;
          background: #285bd4;
          position: absolute;
          left: auto;
          right: 0;
          bottom: -3px;
          transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        }
        &:hover {
          color: #fff;
          transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
          &:after {
            width: 100%;
            left: 0;
            right: auto;
            transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
          }
        }
        &.active {
          color: #fff !important;
          outline: 0;
          text-decoration: none;
          transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);

          &:after {
            width: 100%;
            right: auto;
            left: 0;
            transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
          }
        }
      }
    }
  }
}
