// Styles for the Partners section

#partnersSection {
  padding: 80px 0px 200px;
  position: relative;
  @media (max-width: 480px) {
    padding: 100px 0 40px 0;
  }
  @media (min-width: 2000px) {
    padding: 400px 0px 340px 0px;
  }
  h2 {
    margin-bottom: 125px;
    @media (max-width: 768px) {
      line-height: 1.6;
    }
    @media (max-width: 567px) {
      margin-bottom: 50px;
    }
  }
  a {
    &:after {
      background: transparent;
    }
  }
}
