// All files for About Page

@import "about/hero.scss";
@import "about/story.scss";
@import "about/team.scss";
@import "about/services.scss";

#aboutSection {
  min-height: 100vh !important;
}
