// All styles for Services Component

#aboutServices {
  min-height: 100vh !important;
  background: #0c0c0c !important;
  padding: 80px 0px 200px;
  position: relative;
  @media (max-width: 768px) {
    padding: 200px 0px 150px 0;
  }
  @media (max-width: 480px) {
    padding: 100px 0 40px 0;
  }
  @media (min-width: 2000px) {
    padding: 400px 0px 340px 0px;
  }
  i {
    font-size: 2em;
    color: #285bd4;
  }
  h2 {
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 50px;
  }
  .services-left {
    @media (max-width: 992px) {
      margin-bottom: 75px;
    }
  }
  .services-right {
    padding-top: 12px;
  }
  .services-box {
    margin-top: 15px;
    margin-bottom: 60px;
  }
  h5 {
    color: #888;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 0.9em;
  }
  a {
    color: #aaa;
  }
  ul {
    li {
      line-height: 2.2;
    }
  }
}
