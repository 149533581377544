// All files for Home Page

@import "home/hero.scss";
@import "home/works.scss";

video {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
